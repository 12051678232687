import React from "react"
import { ContentContainer, MainContent, Seo } from "../components"
import { graphql, Link } from "gatsby"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

import "../css/404.css"

const notFound = () => {
  // const featureImage = data.imageSharp.fixed
  return (
    <ContentContainer>
      <Seo
        title="Page Not Found"
        // image={seoImage}
        description="The page you are looking for could not be found."
      />
      <MainContent>
        <div className="container flex-center">
          <section className="not-found-content">
            <h1>404</h1>
            <h2>PAGE NOT FOUND</h2>
            <p>The page you are looking for could not be found.</p>
            <a href="/" className="fat-button">
              Go to Home
            </a>
          </section>
        </div>
      </MainContent>
    </ContentContainer>
  )
}

export default notFound
